@import "../theme/colors.scss";

.header {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 0px;
    padding-bottom: 0px;
    font-size: 18px;
    height: auto;
    min-height: 64px;
    line-height: 64px;
    position: relative;
    background-color: $background-color;
}

.headerContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.refresh {
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: #999;
    transition: all 0.2s;

    &:hover {
        color: #d6d6d6;
    }

    &:active {
        color: #363636;
    }
}

.choiceManager {
    padding: 10px !important;
    // border-radius: 5px;
    width: 130px !important;
    // cursor: pointer;
}

.profile{
    a{
        display: block;
    }
}
.managerContainer {
    margin-left: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: center;
}


.avatarContainer {
    margin-left: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: flex-end;

    .menuDivider {
        height: 40px;
        margin-right: 20px;
    }
}

.menuButton {
    top: 65px;
    left: 0;
    transform: translateY(-50%);
    z-index: 1;
    position: absolute !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-left-width: 0 !important;
}

.opcoesItem {
    margin-bottom: 4px;
}

.optionDivider {
    margin-top: 8px;
    margin-bottom: 8px;
}

.tenancyContainer {
    width: 100%;
    display: flex;
    max-width: 180px;
}
