@import "../theme/colors.scss";

$menu-selection-color: #fad9c1;
$menu-color: $color-primary;

.popupSubMenu {
    ul {
        background-color: #fef6ef !important;
    }
}

.sider {
    height: 100%;
}

.scrollContainer {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    // padding: 0px 8px;
    background-color: $menu-color;

    // Firefox >= 64
    scrollbar-width: thin;
    scrollbar-color: $color-divider-dark;

    // Chrome/Safari
    &::-webkit-scrollbar {
        width: 4px;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $color-divider-dark;
    }

    &::-webkit-scrollbar-track {
        background-color: transparent;
    }

    :global {
        .ant-menu-vertical {
            width: 63px;
        }
    }
}

.menu {
    border: 0;
    background-color: $menu-color;
    padding: 10px;
    color: #fff;
    .subMenu {
        background-color: #7076d6;
        margin-bottom: 5px;
        border-radius: 5px;

        &:active .subMenuText {
            background-color: transparent;
        }

        .subMenuText {
            color: #ffffff;
        }

        &:global(.ant-menu-submenu-open) {
            background-color: #fff;


            .subMenuText {
                font-weight: 500;
                color: #7d7d7d;
            }
        }

        &:global(.ant-menu-submenu-selected) {
            // background-color: $menu-selection-color;
        }

        ul {
            // background-color: $menu-selection-color;
            border-radius: 10px;
        }

        .menuItem {
            width: 100%;

            a {
                // color: #666666;
            }
        }

        .subMenu {

            &:global(.ant-menu-submenu-open),
            ul,
            .menuItem {
                background-color: #fef6ef;
            }
        }

        :global {
            .ant-menu-submenu-arrow {
                color: #6f9dcb
            }

            .ant-menu-submenu-title {
                margin-top: 0px;

                &:active {
                    background-color: transparent;
                }
            }
        }
    }

    .menuItem {
        border-radius: 10px;
        
        &:hover {
            // background-color: #fff;
        }

        a {
            color: $color-text-primary-light;
            // color: #7d7d7d;
        }

        &:hover a {
            font-weight: bold;

        }

        &::after {
            // background-color: #fff;
            // display: none;
        }

        &:global(.ant-menu-item-selected) {
            // background-color: $menu-selection-color;
            background-color: $emphasis-color;

            // background-color: transparent;

            a {
                font-weight: bold;
            }
        }
    }
}

.logo {
    height: 65px;
    line-height: 65px;
    padding: 13px;
    margin: 0;
    text-align: center;
    vertical-align: middle;
    background-color: #fff;
    display: flex;

    a {
        text-decoration: none;
    }

    .logoImage {
        height: 100%;
        display: flex;
        object-fit: contain;
    }

    .logoImageCollapsed {
        width: 100%;
    }
}

.menuItemIcon {
    margin-right: 10px !important;
}

.menuButton {
    position: absolute;
    top: 65px;
    z-index: 1;
    right: 0;
    transform: translateX(50%) translateY(-50%);
}
