@import "../theme/colors.scss";

.button {
    border: 0px;

    &:hover, &:focus {
        opacity: 0.9;
    }

    &:active {
        opacity: 0.6;
    }
}

:global(.ant-input-group.ant-input-group-compact) {
    .button:last-child {
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
    }

    .button:first-child {
        border-top-right-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
    }
}
