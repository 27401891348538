@import "../theme/colors.scss";

.button {
    box-shadow: 0 3px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    border: none;
    background-color: $emphasis-color;
    color: #fff;;

    &:focus,
    &:hover {
        background-color: $emphasis-color;
        background-color: white;
    }

    &:active {
        background-color: $color-primary;
    }
}
