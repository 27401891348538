@import "../theme/colors.scss";

.container {
    .userName {
        font-size: 14px;
        margin-right: 10px;
        color: $color-text-primary-dark;
    }

    .userAvatar {
        background-color: $background-color;
        color: $color-text-primary-dark;
    }
}
