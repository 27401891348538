$color-primary: #070D0D;
$color-primary-dark: #020612;
$color-primary-light: #102c82;
$color-text-on-primary: #000;

$color-accent: #166ABF;
$color-accent-dark: #f0f0f0;
$color-accent-light: #a0faf4;
$color-text-on-accent: #000000;

$hover-color: rgb(226, 226, 226);
$primary-background-color: #efefef;
$background-color: #ffffff;
$background-color-dark: #f6f6f6;
$background-color-table:#FBFBFB;

$color-text-primary-dark: rgba(0, 0, 0, 0.87);
$color-text-secondary-dark: rgba(0, 0, 0, 0.54);
$color-text-half-dark: rgba(0, 0, 0, 0.50);
$color-text-disabled-dark: rgba(0, 0, 0, 0.38);
$color-text-hint-dark: rgba(0, 0, 0, 0.38);
$color-divider-dark: rgba(0, 0, 0, 0.12);

$color-text-primary-light: rgba(255, 255, 255, 1);
$color-text-secondary-light: rgba(255, 255, 255, 0.7);
$color-text-disabled-light: rgba(255, 255, 255, 0.5);
$color-text-hint-light: rgba(255, 255, 255, 0.5);
$color-divider-light: rgba(255, 255, 255, 0.24);

$color-link: #166ABF;

$color-error: #FF2719;
$color-alert: #eaaf26;
$color-success: #43bd2d;

$emphasis-color: #0A414A;
$boder-color: rgba(0,0,0,.06);
